#data-tool-popup-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(235, 235, 235, 0.85);
  top: 0;
  left: 0;
  z-index: 1000;

  &[data-datatool-popup-state="closed"] {
    display: none;
  }

  &[data-datatool-popup-state="open"] {
    display: block;
    animation: showPopup 500ms both;
  }
}

.data-tool-popup {
  position: fixed;
  left: 10%;
  top: 10%;
  right: 10%;
  z-index: 1001;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: $white;
  max-height: 80%;
  overflow: hidden;
  flex-direction: column;

  a {
    color: $secondary-color;
    &.btn {
      color: $white;
    }
  }

  &[data-datatool-popup-state="closed"] {
    display: none;
  }

  &[data-datatool-popup-state="open"] {
    display: flex;
    animation: showPopup 600ms both;
  }
}