.jourbox-menu {
  float: right;
  display: flex;
  transition: padding 0.3s ease;

  ul {
    padding: 0;
    list-style: none;

    li {
      position: relative;

      .icon-arrow {
        padding: 2px 0 0 4px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  &__icon {
    -webkit-transition: color 0.2s;
    -moz-transition: color 0.2s;
    -ms-transition: color 0.2s;
    -o-transition: color 0.2s;
    transition: color 0.2s;
    color: darken($white, 20%);
    font-size: 22px;
    line-height: 0;
    padding-bottom: 2px;
  }

  &__text {
    -webkit-transition: opacity 0.4s ease, color 0.2s ease;
    -moz-transition: opacity 0.4s ease, color 0.2s ease;
    -ms-transition: opacity 0.4s ease, color 0.2s ease;
    -o-transition: opacity 0.4s ease, color 0.2s ease;
    transition: opacity 0.4s ease, color 0.2s ease;
    font-size: 12px;
    color: $theme-grey-8;
    font-variant: all-small-caps;
    opacity: 0;
    overflow: hidden;
  }

  &__list {
    position: relative;
    z-index: 10;
    display: flex;
  }

  &__item {
    margin-left: 2px;
    position: relative;
    max-width: 110px;
    transition: max-width 0.3s ease;

    a {
      padding: 5px 13px 4px;
      margin-bottom: 10px;
      display: block;
      font-size: 16px;
      text-align: center;
      transition: padding 0.4s ease;

      &:after {
        content: "";
        display: block;
        clear: both;
      }

      &[href="#"] {
        cursor: default;
      }
    }

    &--signout {
      * {
        color: $tertiary-color;
      }

      &:hover * {
        color: darken($tertiary-color, 10%);
      }
    }

    &--modules {
      > a {
      }
    }

    &--active,
    &:focus,
    &:hover {
      .jourbox-menu__icon {
        color: $primary-color;
      }

      .jourbox-menu__text {
        color: $white;
      }
    }

    &--nocustom a {
      border-left: 0;
    }
  }

  &__sublist {
    position: absolute;
    background-color: $theme-grey-6;
    display: none;
    z-index: 12;
    margin-left: -110px;
    width: 220px;
    left: 50%;

    .jourbox-menu__sublist {
      background-color: $theme-grey-3;

      .jourbox-menu__subitem {
        border-bottom: 1px solid darken($theme-grey-3, 5%);

        .jourbox-menu__sublist {
          background-color: $theme-grey-1;

          &.jourbox-menu__subitem {
            border-bottom: 1px solid red;
          }
        }
      }
    }
  }

  &__subitem {
    background-color: transparent;
    position: relative;
    width: 100%;
    -webkit-transition: border-color 0.2s, background-color 0.2s, color 0.2s;
    -moz-transition: border-color 0.2s, background-color 0.2s, color 0.2s;
    -ms-transition: border-color 0.2s, background-color 0.2s, color 0.2s;
    -o-transition: border-color 0.2s, background-color 0.2s, color 0.2s;
    transition: border-color 0.2s, background-color 0.2s, color 0.2s;
    border-bottom: 1px solid darken($theme-grey-6, 5%);

    &:last-child {
      border: 0;
    }

    a {
      text-align: left;
      font-size: 14px;
      color: $theme-grey-9;
      padding: 15px;
      margin: 0;
      line-height: 15px;

      .icon-arrow {
        padding-top: 3px;

        &.desktop {
          display: inline;
        }
        &.mobile {
          display: none;
        }
      }

      .jourbox-menu__subitem {
        position: absolute;
        left: 150px;
        top: -1px;
        display: none;
      }
    }

    &--active,
    &:hover,
    &:focus {
      background-color: $theme-grey-7;
    }

    &--parent:hover .jourbox-menu__sublist {
      display: block;
      animation: fadeIn 300ms ease;
    }
  }

  &__mobile {
    display: none;
    color: darken($white, 20%);
    font-size: 22px;
    line-height: 0;
    margin-top: 10px;

    &:hover {
      color: white;
      cursor: pointer;
    }
  }

  @media (min-width: $device-md) {
    &__item {
      &--parent:hover > .jourbox-menu__sublist {
        display: block;
        animation: fadeIn 300ms ease;
      }
    }

    &__sublist {
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);

    }

    &__subitem {
      .jourbox-menu__sublist {
        display: none;
        position: absolute;
        left: 220px;
        top: 0;
        margin: 0;
      }
    }

    &.show-text {
      .jourbox-menu__text {
        height: auto;
        opacity: 1;
      }
    }

    &--sticky {
      padding-top: 5px;

      .jourbox-menu__item {
        max-width: 38px !important; //overwrite inline styling

        > a {
          padding: 0;
          margin-bottom: 6px;
          border: 0;
        }

        > .jourbox-menu__sublist {
          right: 0;
          margin-left: 0;
          left: unset;
        }
      }

      .jourbox-menu__text {
        height: 0;
        opacity: 0;
      }
    }
  }

  @media (max-width: $device-md) {

    &__mobile {
      display: block;
      color: $theme-grey-3;

      &--show,
      &--show:hover {
        color: $theme-grey-8;
      }
    }

    &__list {
      background-color: $theme-grey-10;
      display: block;
      position: absolute;
      right: -300px;
      width: 300px;
      top: 70px;
      margin: 0;
      transition: right 0.4s ease-in;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, .4);
      max-height: calc(100vh - 80px);
      overflow-y: scroll;

      &--show {
        right: -17px;
      }
    }

    &__item {
      margin: 0;
      max-width: inherit;

      a {
        width: 100%;
      }

      > a {
        padding: 0;
        margin: 0;
        display: table;
      }

      &--parent:hover .jourbox-menu__sublist {
        animation: none;

        &:hover {
          -webkit-animation: none;
          -o-animation: none;
          animation: none;
        }
      }
    }

    &__icon {
      display: table-cell;
      width: 40px;
      padding: 13px 12px;
      text-align: center;
      box-sizing: content-box;
    }

    &__text {
      display: table-cell;
      padding: 13px 20px 0 0;
      font-size: 17px;
      text-align: left;
      vertical-align: top;
      font-variant: normal;
      height: initial;
      opacity: 1;

      i {
        padding: 4px 0 0 10px;
      }
    }

    &__subitem {
      width: 100%;

      a {
        padding: 15px 24px;
        font-size: 15px;

        .icon-arrow {
          &.desktop {
            display: none;
          }
          &.mobile {
            display: inline;
          }
        }
      }
    }

    &__sublist {
      display: block;
      height: auto;
      width: 100%;
      max-width: 100%;
      position: unset;
      margin: 0;
      -webkit-transition: max-height 0.3s ease;
      -moz-transition: max-height 0.3s ease;
      -ms-transition: max-height 0.3s ease;
      -o-transition: max-height 0.3s ease;
      transition: max-height 0.3s ease;
      overflow: hidden;

      &--show {
      }
    }

    &__background {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 0;
      position: fixed;
      background-color: rgba($theme-grey-10, 0.9);
      height: 100vh;
      overflow: hidden;
      opacity: 0;
      -webkit-transition: z-index 0.3s ease, opacity 0.3s ease;
      -moz-transition: z-index 0.3s ease, opacity 0.3s ease;
      -ms-transition: z-index 0.3s ease, opacity 0.3s ease;
      -o-transition: z-index 0.3s ease, opacity 0.3s ease;
      transition: z-index 0.3s ease, opacity 0.3s ease;

      &.jourbox-menu__background--show {
        z-index: 992;
        opacity: 1;
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}