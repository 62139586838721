/* -------------------------------------
   Spinner
--------------------------------------*/
body #jourbox-page{
  .load {
    background-color: rgba($theme-grey-11, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    opacity: 1;
    transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -webkit-transition: opacity .5s ease-in-out;

    .circle {
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 45%;
      margin: -42px 10px 10px -42px;
      padding: 10px;
      width: 84px;

      img {
        display: block;

        &:nth-child(2) {
          margin-left: 95px;
        }
      }
    }

    &.top {
      z-index: 9997;
    }

    &.fadeout {
      opacity: 0;
    }

    &.fadein {
      opacity: 0;

      &.complete {
        opacity: 1;
      }
    }

    &.display-none {
      display: none;
    }
  }
}
