/* -------------------------------------
   Colors
--------------------------------------*/
/* ---- General ----*/
$primary-color: #28375f;
$color-primary: #28375f;
$secondary-color: #658dc6;
$tertiary-color: #e49119;
$color-pistachio: pink;

$background-color: #dce8f9;
$background-color-secondary: #f0f8ff;

$black: #000000;
$theme-grey-11: #333333;
$theme-grey-10: #424146;
$theme-grey-9: #56545a;
$theme-grey-8: #7d7b80;
$theme-grey-7: #b7b6bf;
$theme-grey-6: #cccad0;
$theme-grey-5: #cbcad0;
$theme-grey-4: #dddce2;
$theme-grey-3: #ececef;
$theme-grey-2: #edecef;
$theme-grey-1: #f9f9f9;
$white: #ffffff;
$color-white: $white;

$color-warning: #b73434;
$color-delete: #d20606;
$color-danger: #eacdcd;
$color-alert: red;
$color-succes: #b5d4c6;
$color-info: #cdd9ea;
$color-positive: #4ea779;

$header-color: $theme-grey-11;
$link-color: $theme-grey-11;
$stripes: #dce8f9;

/* ---- Header ----*/
$background-color-header: #e3e3e3;

/* ---- Navigation ----*/
$color-mainmenu-color: $theme-grey-11;
$color-mainmenu-background: #e7e7e7;
$color-mainmenu-icon: $theme-grey-11;
$color-mainmenu-active-background: $primary-color;
$color-mainmenu-hover: $primary-color;
$color-mainmenu-sub-background: $theme-grey-1;
$color-mainmenu-sub-hover-background: $theme-grey-7;
$color-mainmenu-sub-hover-color: $secondary-color;

/* --- Fonts --- */
$font-size-base: 1.6rem;
$font-size-extra-small: 1.2rem;
$font-size-small: 1.4rem;
$font-size-regular: $font-size-base;
$font-size-large: 2.2rem;
$font-size-x-large: 3.0rem;
$font-size-xx-large: 4.5rem;

/* --- Buttons --- */
$button-border-radius: 20px;
$button-padding-y: 1.1rem;
$button-padding-x: 3rem;

/* --- Form defaults ---*/
$input-color: $theme-grey-8;
$input-color-placeholder: $theme-grey-8;
$input-color-border: transparent;
$input-color-border-focus: $theme-grey-8;
$input-color-background: white;
$input-color-background-disabled: lighten(grey, 40%);
$color-disabled: grey;

$label-color: $theme-grey-8;

$input-border: 1px solid lightgrey;
$input-border-radius: 3px;

$input-padding-y: 0.8rem;
$input-padding-x: 1rem;
$input-padding: $input-padding-y $input-padding-x;

// TODO move vars that are used only once
$button-border-radius: 20px;
$button-padding-y: 1.1rem;
$button-padding-x: 3rem;

/* -------------------------------------
   Images
--------------------------------------*/
$image-background-header: '/assets/jourbox-theme/img/header_background.jpg';

//Dark theme
$image-background-header: '';
$theme-background-header: $theme-grey-11;

$primary-color: #99b6e0;
$secondary-color: #515f86;

$color-mainmenu-background: $theme-grey-10;
$color-mainmenu-border: $theme-grey-10;
$color-mainmenu-icon: $primary-color;
$color-mainmenu-active-background: $secondary-color;
$color-mainmenu-hover: lighten($theme-grey-10, 10%);
$color-mainmenu-hover-border: $white;
$color-mainmenu-sub-background: $theme-grey-1;
$color-mainmenu-sub-hover-background: $theme-grey-7;
$color-mainmenu-sub-hover-color: $secondary-color;

/* -------------------------------------
   Devices
--------------------------------------*/
$device-sm: 768px;
$device-md: 992px;
$device-lg: 1200px;

$font-family-zilla: 'Zilla Slab', serif;

/* -------------------------------------
   Notifications
--------------------------------------*/
$color-gunmetal: #213542;
$color-secondary: $color-gunmetal;
$zindex-notification: 1070;
$color-ghost-white: #F6F7FB;