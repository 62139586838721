#user_bar {
  margin-left: 7px;

  form.logout {
    float: left;

    .form-group {
      margin: 0;
    }

    input[type="submit"] {
      margin: 0;
      height: 34px;
      background-color: $secondary-color;
      border: none;
      box-shadow: none;
      transition: color 0.2s, background-color 0.2s;
      padding-right: 35px;
      padding-top: 7px;
      border-radius: 0 3px 3px 0;
    }

    span.glyphicon {
      color: $white;
      padding-left: 0;
    }

    &:hover input {
      background-color: $primary-color;
    }
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}