/* ---------------------------------------------------------
    SignInAuthor
----------------------------------------------------------*/
.jourbox-theme {
    &.user-ui-login {
        display: block;

        .user-ui-login__box {
            max-width: 600px;
            margin: auto;
        }

        form {
            .errors {
                margin-top: 20px;

                > div {
                    float: left;
                    margin: -4px 15px 0 2px;
                    font-size: 22px;
                }

                ul {
                    list-style: none;
                    padding: 0;

                    li {
                        margin-left: 30px;
                    }
                }
            }


        }
    }

    .btn-row {
        text-align: right;
    }
}

@media screen and (max-width: 600px) {
    section.signin form {
        margin: 50px auto 0 auto;
    }
}
