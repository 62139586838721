.jourbox-header {
  height: 80px;
  width: 100%;
  z-index: 9999;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1) inset;
  background: $theme-background-header url($image-background-header) calc(50% - 390px) top;
  padding-top: 20px;
  position: fixed;
  top: 0;
  -webkit-transition: height 0.4s ease, padding-top 0.4s ease;
  -moz-transition: height 0.4s ease, padding-top 0.4s ease;
  -ms-transition: height 0.4s ease, padding-top 0.4s ease;
  -o-transition: height 0.4s ease, padding-top 0.4s ease;
  transition: height 0.4s ease, padding-top 0.4s ease;

  &__logo {
    display: inline-block;
    margin: 8px 0 0 0;
    transition: line-height 0.4s ease, margin 0.4s ease;
    line-height: 40px;

    a {
      color: $secondary-color;
      font-size: 36px;
      font-weight: 400;
      transition: opacity 0.2s ease, font-size 0.4s ease;
      margin: 0;

      strong {
        color: $primary-color;
      }

      &:hover {
        text-decoration: none;
        opacity: 0.8;
      }
    }
  }

  @media (min-width: $device-md) {
    &--sticky {
      height: 40px;
      padding-top: 5px;

      .jourbox-header__logo {
        margin: 0;
        line-height: 28px;

        a {
          font-size: 26px;
        }
      }
    }
  }

  @media (max-width: $device-md) {
    height: 70px;
    padding: 15px 5px 5px;

    &__logo {
      margin: 0;
    }
  }
}

body.jourbox-theme__body--hideheader {
  height: 100%;
  padding: 0;
}