.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  line-height: 1.2;

  > thead, > tbody, > tfoot {
    > tr {
      > th, > td {
        padding: 1.75rem;
        vertical-align: middle;
        text-align: left;

        > a {
          text-decoration: none;
          vertical-align: middle;
        }
      }
    }
  }

  > thead {
    > tr {
      box-shadow: 0 2px 2px -2px rgba(0,0,0,0.2);
      font-weight: bold;
    }
  }

  &--striped > tbody > tr {
    &:nth-child(even) td {
      background-color: #d4d2d8;
    }

    &:nth-child(odd) td {
      background-color: $theme-grey-4;
    }
  }
}