/* -------------------------------------
   General
--------------------------------------*/
@import 'variables';
@import 'forms';
@import 'keyframes';
/* -------------------------------------
   Layout
--------------------------------------*/
@import 'bootstrap';
@import 'layout';
@import 'layout/_header';
@import 'layout/navigation';
@import 'layout/content';
@import 'components/spinner';
@import 'components/user_bar';
@import 'components/popup';
@import 'components/panel';
@import "components/btn";
@import "components/badge";
@import "components/table";
@import "features/notification";
@import 'modules/user_ui';
@import 'forms/form-group';

html,
body {
    margin: 0;
}

body {
    height: auto !important;
    overflow-x: hidden;
    overflow-y: inherit !important;
    padding: 80px 0 0;
    background-color: $theme-grey-3;

    h1, h2, h3, h4, h5 {
        font-family: 'Zilla Slab', serif;
        color: $header-color;
    }

    strong {
        font-weight: 600;
    }

    a {
        color: $link-color;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
    }
}

@media (min-width: $device-md) {
    html {
        //overflow-y: hidden;
    }
    body {
        //overflow-y: scroll !important;
    }

    html, body {
        height: 100% !important;
    }
}

@media (max-width: $device-md) {
    .container {
        width: 100%;
    }
}