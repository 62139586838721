.form-group {
  &--horizontals {
    display: flex;
    margin-bottom: 10px;

    label {

      &.control-label {
        font-weight: 100;
        color: $theme-grey-8;
        width: 40%;
        background-color: $theme-grey-4;
        -webkit-border-radius: 3px 0 0 3px;
        -moz-border-radius: 3px 0 0 3px;
        border-radius: 3px 0 0 3px;
        padding: 7px;
        box-shadow: none;
      }
    }

    .form-control {
      border-radius: 0 3px 3px 0;
      padding: 7px;
      border: 0;
      margin: 0;
      box-shadow: none;

      &[type=checkbox] {

      }
    }
  }

  &--nolabel {
    label {
      &.control-label {
        display: none;
      }
    }

  }
}

.form-control {
  border-radius: 3px;
  padding: 7px;
  border: 0;
  margin: 0;
  box-shadow: none;

  &[type=checkbox] {
    width: 20px;
  }
}