#jourbox-page {
  .content {
    background: $theme-grey-1;
    width: 100%;
    padding: 40px 0;

    &.grey {
      background: $theme-grey-3;
    }

    &.top {
      padding-top: 50px;
    }

    &__title {
      font-family: 'Zilla Slab', serif;
      color: $theme-grey-11;
      margin: 20px 0;
      font-weight: 500;
      line-height: 1.1;
      font-size: 36px;
    }

    &__subtitle {
      margin: 0 0 20px 0;
      font-family: 'Zilla Slab', serif;
      color: $theme-grey-8;
      font-weight: 100;
      line-height: 1.1;
      font-size: 2rem;
    }
  }
}
