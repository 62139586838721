#jourbox-page {
    .panel {
        background: $theme-grey-3;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        border: 0;
        box-shadow: none;

        &__title {
            background-color: $theme-grey-10;
            padding: 15px;
            font-family: 'Zilla Slab', serif;
            font-weight: 500;
            font-size: 2.2rem;
            color: $theme-grey-2;
            border-radius: 5px 5px 0 0;
            margin: 0;
        }

        &__subtitle {
            margin: 0;
            padding: 15px;
            font-family: 'Zilla Slab', serif;
            font-weight: 400;
            font-size: 1.4rem;
            color: $theme-grey-8;
        }

        &__body {
            padding: 15px;
            flex-grow: 1;
        }

        &__footer {
            background-color: $theme-grey-4;
            padding: 15px;
            color: $theme-grey-8;
        }

        &.darkgrey {
            background-color: $theme-grey-4;
        }

        &.toggle {
            .panel__title:before {
                content: "";
                display: block;
                float: right;
            }
        }

        &.toggle {
            .panel__subtitle,
            .panel__body {
                display: none;
            }

            .panel__title {
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;

                &:before {
                    content: "\f107";
                    display: block;
                    float: right;
                    font: normal normal normal 14px/1 FontAwesome;
                    padding: 5px 5px 0 0;
                    font-size: 16px;
                    color: $theme-grey-7;
                    -webkit-transition: color 0.2s ease;
                    -moz-transition: color 0.2s ease;
                    -ms-transition: color 0.2s ease;
                    -o-transition: color 0.2s ease;
                    transition: color 0.2s ease;
                }

                &:hover {
                    color: $theme-grey-1;
                    cursor: pointer;

                    &:before {
                        color: $theme-grey-1;
                    }
                }
            }

            &.open {
                .panel__title {
                    -webkit-border-radius: 5px 5px 0 0;
                    -moz-border-radius: 5px 5px 0 0;
                    border-radius: 5px 5px 0 0;

                    &:before {
                        content: "\f106";
                        display: block;
                        float: right;
                        font: normal normal normal 14px/1 FontAwesome;
                        padding: 5px 5px 0 0;
                        font-size: 16px;
                        color: #b7b6bf;
                    }
                }

                .panel__subtitle,
                .panel__body {
                    display: block;
                }
            }
        }

    }
}
