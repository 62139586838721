.badge {
    display: inline-block;
    font-family: $font-family-zilla;
    padding: .4em .8em;
    font-size: 80%;
    font-weight: 700;
    line-height: 1;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10rem;
    background-color: $color-primary;
    color: $white;

    &--positive {
        background-color: $color-positive;
    }
    &--alert {
        background-color: $color-alert;
    }
    &--secondary {
        background-color: $secondary-color;
    }
    &--tertiary {
        background-color: $tertiary-color;
    }

    @at-root a#{&} {
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: dark($color-info);
            color: $color-white;
        }

        &--positive {
            &:hover,
            &:focus {
                background-color: dark($color-positive);
            }
        }

        &--secondary {
            &:hover,
            &:focus {
                background-color: dark($secondary-color);
            }
        }

        &--tertiary {
            &:hover,
            &:focus {
                background-color: dark($tertiary-color);
            }
        }

        &--alert {
            &:hover,
            &:focus {
                background-color: dark($color-alert);
            }
        }
    }

    [class^="icon-"],
    [class*=' icon-'] {
        font-size: 90%;
        vertical-align: -4%;
    }

    + .badge {
        margin-left: 4px;
    }
}
