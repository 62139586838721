@mixin notification-variant($icon: null) {
    @if ($icon != null) {
        padding-left: 115px;
    } @else {
        min-height: inherit;
    }

    &:before {
        @if ($icon != null) {
            background: url($icon) no-repeat center bottom;
            background-size: contain;
        }
    }
}

@keyframes notification-slide-in-right {
    from {
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes notification-slide-out-right {
    0% {
        max-height: 108px;
        margin-bottom: 10px;
    }

    30% {
        transform: translateX(0);
    }

    60% {
        max-height: 108px;
        margin-bottom: 10px;
        transform: translateX(200%);
    }

    100% {
        max-height: 0;
        margin-bottom: 0;
        transform: translateX(200%);
    }
}

.notification-list {
    position: fixed;
    right: 0;
    padding: 0 1rem 0.5rem 1rem;
    box-sizing: border-box;
    overflow: hidden;
    z-index: $zindex-notification;
}

.notification {
    padding: 0.5rem 0;

    &__box {
        background: $theme-grey-11;
        color: $theme-grey-1;
        padding: 2rem;
        box-sizing: border-box;
        width: 389px;
        min-height: 108px;
        max-height: 200px;
        display: block;
        position: relative;
        overflow: hidden;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;

        &::before {
            display: inline-block;
            width: 70px;
            height: 70px;
            position: absolute;
            top: 20px;
            left: 20px;
            content: " ";
        }
    }

    &--slide-in {
        animation-duration: 0.3s;
        animation-fill-mode: both;
        animation-name: notification-slide-in-right;
    }

    &--slide-out {
        animation: notification-slide-out-right 0.3s;
        animation-fill-mode: forwards;
    }

    &__title {
        font-size: 2rem;
        margin-bottom: 10px;
        font-family: $font-family-zilla;
    }

    &__text {
        font-size: 1.3rem;
        line-height: 1.8rem;

        a {
            color: inherit;
            font-weight: 400;
        }
    }

    &__paragraph {
        margin: 0;
    }

    .close {
        top: 10px;
        right: 10px;
        opacity: 0.5;
        font-size: 1.4rem;
        color: #aeacb7;
        border: 0;
        text-shadow: none;
        position: absolute;
        font-weight: 100;
        -webkit-transition: color 0.1s;
        -moz-transition: color 0.1s;
        -ms-transition: color 0.1s;
        -o-transition: color 0.1s;
        transition: color 0.1s;

        &:after {
            color: $theme-grey-1;
        }

        &:hover {
            color: $theme-grey-1;
        }
    }

    &--success {
        @include notification-variant($icon: '../img/icons/tick-inside-circle.svg');
    }

    &--error {
        @include notification-variant($icon: '../img/icons/rounded-block-sign.svg');
    }

    &--notice {
        @include notification-variant($icon: '../img/icons/kodus.png');
    }
}
