@mixin button-colors($color) {
    color: lighten($color: $color, $amount: 50%);
    background: $color;
}

@mixin button-colors-with-states($color) {
    @include button-colors($color);

    &:focus,
    &:hover {
        color: lighten($color: $color, $amount: 50%);
        background-color: darken($color, $amount: 10%);
    }
}

.btn {
    font-size: 1.4rem;
    font-family: inherit;
    line-height: 1.3;
    letter-spacing: 0.05rem;
    text-decoration: none;
    border-radius: 3px;
    border: 0;
    padding: 10px 15px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: background-color .15s ease-in-out;
    @include button-colors-with-states($color: $secondary-color);

    &:focus,
    &:hover {
        outline: none;
    }

    [class^="icon-"],
    [class*=" icon-"] {
        font-size: $font-size-large;
        line-height: 0;
        vertical-align: -5px;
    }

    &.btn--primary {
        @include button-colors-with-states($color: $primary-color);
    }
    &.btn--danger {
        @include button-colors-with-states($color: $color-warning);
    }
    &.btn--grey {
        @include button-colors-with-states($color: $theme-grey-7);
    }

    &[disabled],
    &.disabled {
        @include button-colors($color: $theme-grey-9);
        cursor: not-allowed;
        &:focus, &:hover {
            @include button-colors($color: $theme-grey-10);
        }
    }

    + .btn {
        margin-left: 1.2rem; // space between multiple consecutive buttons
    }
}
