form.activate {
    label,
    input[type="radio"] + span,
    input[type="radio"] + span::before,
    label,
    input[type="checkbox"] + span,
    input[type="checkbox"] + span::before {
        display: inline-block;
        vertical-align: middle;
    }

    label *,
    label * {
        cursor: pointer;
    }

    input[type="radio"],
    input[type="checkbox"] {
        opacity: 0;
        position: absolute;
    }

    input[type="radio"] + span,
    input[type="checkbox"] + span {
        font-family: sans-serif;
        color: #696969;
        font-size: 16px;
        padding-right: 10px;
    }

    label:hover span::before,
    label:hover span::before {
        -moz-box-shadow: 0 0 2px #ccc;
        -webkit-box-shadow: 0 0 2px #ccc;
        box-shadow: 0 0 2px #ccc;
    }

    label:hover span,
    label:hover span {
        color: #000;
    }

    input[type="radio"] + span::before,
    input[type="checkbox"] + span::before {
        content: "";
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        border: 1px solid $theme-grey-4;
        line-height: 29px;
        text-align: center;
        background-color: $white;
    }

    input[type="radio"]:checked + span::before,
    input[type="checkbox"]:checked + span::before {
        color: $secondary-color;
    }

    input[type="radio"]:disabled + span,
    input[type="checkbox"]:disabled + span {
        cursor: default;

        -moz-opacity: .4;
        -webkit-opacity: .4;
        opacity: .4;
    }

    input[type="checkbox"] + span::before {
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
    }

    input[type="radio"]:checked + span::before {
        content: "\2022";
        font-size: 30px;
        margin-top: -1px;
    }

    input[type="checkbox"]:checked + span::before {
        content: "\2714";
        font-size: 28px;
    }

    input[class="blue"] + span::before {
        border: solid 1px blue;
        background: #B2DBFF;
        background: -moz-radial-gradient(#B2DBFF, #dfdfdf);
        background: -webkit-radial-gradient(#B2DBFF, #dfdfdf);
        background: -ms-radial-gradient(#B2DBFF, #dfdfdf);
        background: -o-radial-gradient(#B2DBFF, #dfdfdf);
        background: radial-gradient(#B2DBFF, #dfdfdf);
    }

    input[class="blue"]:checked + span::before {
        color: darkblue;
    }
}

form {

    fieldset {
    }

    .checkbox {
        margin: 0 0 2px 20px;
    }

    input[type="submit"] {
        background-color: $secondary-color;
        border: none;
        padding: 7px 12px 6px 12px;
        border-radius: 3px;
        font-size: 16px;
        margin-top: 10px;
        color: $white;
        transition: background-color 0.4s ease, color 0.4s ease;
        font-family: 'Zilla Slab', serif;

        &:focus,
        &:hover {
            background-color: $primary-color;
            color: darken($white, 10%);
        }
    }

    &.author-emails {
        border-left: 3px solid $background-color;
        padding: 5px 5px;
        margin-bottom: 5px;
        background-color: $background-color-secondary;

        input[type=submit] {
            background: transparent;
            padding: 0px 6px;
            color: $color-delete;
            font-weight: bold;
            font-size: 16px;
            margin: 0 0 0 5px;
            float: right;
            font-family: Arial, sans-serif;

            &:hover {
                color: darken($color-delete, 20%);
            }
        }

        &.main {
            background-color: #f1f1f1;
            border-color: #d0d0d0;
        }
    }

    &.add-email {
        border-top: 1px dashed $background-color;
        padding-top: 10px;
        margin-top: 15px;

        input[type=email] {
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 0px;
            padding: 0px 10px;
        }
    }

    input:-webkit-autofill {
        background-color: white;
    }
}

