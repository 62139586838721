/* ---------------------------------------------------------
    JourBox Layout
----------------------------------------------------------*/
#jourbox-page {
  .content-container {
    position: relative;
    z-index: 3;
    background: $theme-grey-1;

    &.color-grey {
      background: $theme-grey-3;
    }

    &.color-blue {
      background-color: $background-color-secondary;
    }

    &.top-padding {
      padding-top: 40px;
    }

    &.extra-padding {
      padding: 70px 0;

      h2 {
        margin-bottom: 30px;
      }
    }
  }
}

.margin-50 {
  margin: 50px;
}

.padding-50 {
  padding: 50px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.seperator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.block-center {
  margin: auto;
}

.inline-center {
  text-align: center;
}

/* Lifted corners */

.lifted {
  -moz-border-radius: 4px;
  border-radius: 4px;

  &:before,
  &:after {
    bottom: 15px;
    left: 10px;
    width: 50%;
    height: 20%;
    max-width: 300px;
    -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.25);
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.25);
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }

  &:after {
    right: 10px;
    left: auto;
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    transform: rotate(3deg);
  }
}

footer#datatool-footer {
  text-align: center;
  background-color: $theme-grey-10;
  padding: 40px 0;

  p {
    margin: 0;
    color: $theme-grey-3;
  }
}

.warning-no-data {
  margin-top: 20px;
  color: $color-warning;
  font-family: 'Zilla Slab', serif;
  text-align: center;
  font-size: 50px;

  i {
    display: block;
    font-size: 80px;
    margin: 40px 10px 20px 10px;
  }
}